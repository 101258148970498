import React, { useEffect, useState } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import './especialidade.scss'
import './servicos.scss'
import trackEvent from "../analyticsHelpers"
import Ellipse1 from '../images/ellipse-2.png'
import Ellipse2 from '../images/ellipse-specialist2.png'
import Ellipse3 from '../images/ellipse-specialist3.png'
import EllipseMob from '../images/ellipse-specialist-mob.png'
import Ellipse2Mob from '../images/ellipse-specialist2-mob.png'
import RegularButton from "../components/atoms/buttons/RegularButton"
import Collapsible from "../components/collapsible"
import { goToApp } from "../utils"
import EspecialidadeImg from '../images/img-servicos.png'
import Planejando from '../images/img-theia-planejando.png'
import Gravidez from '../images/img-theia-gravidez.png'
import MamaeBebe from '../images/img-theia-mamae-bebe.png'
import Img1 from '../images/img1-servicos.png'
import Img2 from '../images/img2-servicos.png'

const Servicos = () => {
  useEffect(() => {
    trackEvent("sitenovo-serviços-visualizou-tela")
  }, [])

  return (
    <Layout>
      <GatsbySeo
        title='Theia | Serviços'
        description='Conheça e compare os serviços da Theia para gravidez e entenda qual é melhor para o seu momento'
        language='pt-br'
        canonical='https://blog.theia.com.br/servicos'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/servicos',
          title: 'Theia | Serviços',
          description: 'Conheça e compare os serviços da Theia para gravidez e entenda qual é melhor para o seu momento',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Serviços'
        }, {
          name: 'dc:description',
          content: 'Conheça e compare os serviços da Theia para gravidez e entenda qual é melhor para o seu momento'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Conheça e compare os serviços da Theia para gravidez e entenda qual é melhor para o seu momento',
        },
         {
          name: 'keywords',
          content:  'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="wrapper-section-row servicos-topo relative">
        <h1 className="text-center text-primary text-dsTitle2 lg:text-dsTitle1 item1">
          Do planejamento, à gravidez e primeiro ano do bebê, desenhamos programas para acompanhar você em cada passo dessa jornada
        </h1>
        <RegularButton
          onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-serviços-clicou-comece-seu-programa')}
          label="Comece seu programa"
          extraClass="btn-section-1 mx-auto item2"
        />
        <img alt="" src={Img1} className="img1" />
        <img alt="" src={Img2} className="img2" />
      </section>

      <section className="wrapper-section-row servicos-container">
        <div className="item3">
          <div className="flex justify-between items-center lg:flex-row flex-col">
            <img src={Planejando} alt="" />
            <div className="left-side">
              <h3 className="text-titleMedium lg:text-dsTitle2 text-primary text-center lg:text-left">Planejando engravidar</h3>
              <p className="text-dsTextSmal text-textSecondary mt-2 mb-6 text-center lg:text-left">
                O desejo de engravidar traz muitas dúvidas, além da ansiedade para que isso ocorra rapidamente. Planejar com antecedência pode ajudar muito nesse processo. Nessa fase te ajudamos com conteúdos, apoio e consultas com profissionais que te ajudarão entender seu corpo, suas vontades, e o que você pode já começar a fazer para ter uma gravidez saudável e tranquila.
              </p>
              <RegularButton
                onClick={() => goToApp('https://atendimento.theia.com.br/whatsapp', 'sitenovo-serviços-clicou-saiba-mais-planejando-engravidar')}
                label="Saber mais"
                variant="secondary"
              />
            </div>
          </div>
        </div>

        <div className="item3 mt-16 lg:mt-0">
          <div className="flex justify-between items-center lg:flex-row flex-col">
            <img src={Gravidez} alt="" className="order-1 lg:order-2" />
            <div className="right-side order-2 lg:order-1">
              <h3 className="text-titleMedium lg:text-dsTitle2 text-primary text-center lg:text-left">Gravidez</h3>
              <p className="text-dsTextSmal text-textSecondary mt-2 mb-6 text-center lg:text-left">
                A Gravidez vem com um turbilhão de medos, dúvidas, inseguranças. Desenhamos um programa para ficar ao seu lado a cada passo, com profissionais que você pode confiar e informação de qualidade.
              </p>
              <RegularButton
                onClick={() => goToApp('/theia-gravidez', 'sitenovo-serviços-clicou-saiba-mais-gravidez')}
                label="Saber mais"
                variant="secondary"
              />
            </div>
          </div>
        </div>

        <div className="item3 mt-16 lg:mt-0">
          <div className="flex justify-between items-center lg:flex-row flex-col">
            <img src={MamaeBebe} alt="" />
            <div className="left-side">
              <h3 className="text-titleMedium lg:text-dsTitle2 text-primary text-center lg:text-left">Mamãe bebê</h3>
              <p className="text-dsTextSmal text-textSecondary mt-2 mb-6 text-center lg:text-left">
                O primeiro ano de vida do bebê não é fácil. Você está vivendo uma série de questões físicas e emocionais com a nova maternidade enquanto o foco se volta totalmente para o bebê que tem várias necessidades. Desenvolvemos esse programa para acompanhar você e seu bebê nessa jornada do primeiro ano, com informação de qualidade, indicação de consultas com profissionais que você pode confiar, e todo apoio e acolhimento da Theia.
              </p>
              <RegularButton
                onClick={() => goToApp('/mamae-bebe', 'sitenovo-serviços-clicou-saiba-mais-mamae-bebe')}
                label="Saber mais"
                variant="secondary"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="relative especialidade-content grid-wrapper-section items-center lg:items-start overflow-hidden">
        <img src={Ellipse3} alt="" className="ellipse3" />
        <div className="item1">
          <h2 className="text-titleMedium lg:text-dsTitle1 text-primary main-title text-center lg:text-left mb-6">
            Profissionais experientes, cuidando de você de forma completa
          </h2>
          <RegularButton
            onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-serviços-clicou-botao-comece-programa')}
            label="Comece seu programa"
          />
        </div>
        <div className="item2 self-end internas relative">
          <img src={Ellipse1} alt="" className="ellipse1 hidden md:block servicos-ellipse1" />
          <img src={Ellipse2} alt="" className="ellipse2 hidden md:block servicos-ellipse2" />
          <img src={EllipseMob} alt="" className="ellipse5 block md:hidden" />
          <img src={Ellipse2Mob} alt="" className="ellipse4 block md:hidden" />
          <img src={EspecialidadeImg} alt="" style={{ width: '540px', float: 'right' }} className="relative main-specialty-img" />
        </div>
      </section>
      <section className="wrapper-section-row servicos-perguntas">
        <h2 className="text-primary text-titleMedium lg:text-dsTitle2 text-center item1">Perguntas frequentes</h2>
        <div className="item2">
          <Collapsible open={false} header='Posso reembolsar esses valores?'>
            <div>
              <p>
              Sim! Se você tem cobertura de reembolso no seu plano de saúde nossas notas fiscais são preparadas para pedido de reembolso. No pagamento por consulta você recebe nota fiscal para reembolso e com a nota fiscal do pagamento do plano Theia Gravidez você tem até R$400 reembolsáveis e os R$149 restantes podem ser declarados no imposto de renda para dedução.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='Em quais hospitais são feitos os partos?'>
            <div>
              <p>
              Por enquanto, nosso time atua nos hospitais Santa Joana, Pro Matre, São Luiz e Einstein em São Paulo.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='Os atendimentos são só online?'>
            <div>
              <p>
                Temos atendimentos presenciais para algumas das nossas especialidades como obstetras, para garantir um pré-natal muito bem feito. Outras especialidades como nutricionistas por enquanto só fazem atendimento online.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='Vocês fazem parto em casa?'>
            <div>
              <p>
              Nossa equipe multidisciplinar trabalha em conjunto para a mulher ter todas as informações necessárias para decidir o que é melhor pra ela desde o planejamento gestacional até o parto.
              No momento realizamos o parto com a segurança das melhores maternidades de SP. Ainda estamos analisando como poderemos, no futuro, oferecer essa mesma segurança para partos não hospitalares.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='O parto está incluso no plano gravidez?'>
            <div>
              <p>
              O valor do parto não está incluso no plano Gravidez, ele é contratado à parte. Um parto com o time de parto Theia, que inclui sua obstetra Theia, uma médica assistente, uma enfermeira obstetriz e um médico anestesista, tem valor de R$14 mil.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='Qual forma de pagamento?'>
            <div>
              <p>
              O pagamento é feito por cartão de crédito no site da Theia.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='Tem cobertura hospitalar?'>
            <div>
              <p>
                Theia não faz cobertura hospitalar ou de exames. Exames e hospital devem ser pagos pelo seu plano de saúde, ou de forma particular.
              </p>
            </div>
          </Collapsible>
          <Collapsible open={false} header='Posso comecar pelo Theia por uso e depois ir para o Theia Gravidez?'>
            <div>
              <p>
                Pode sim! É uma ótima opção para conhecer nossos profissionais e depois decidir pelo melhor custo benefício ofertado pelo plano Theia Gravidez.
              </p>
            </div>
          </Collapsible>
        </div>
      </section>
     
    </Layout>
  )
}

export default Servicos